<template>
  <div style="padding:10px">
    <el-table :data="tableData">
      <el-table-column label="PorjectCode" prop="PROJECT"></el-table-column>
      <el-table-column label="Name of Organization" prop="form_data.agencyName"></el-table-column>
      <el-table-column label="Website" prop="form_data.web"></el-table-column>
      <el-table-column label="Organization Type" prop="form_data.agencyType"></el-table-column>
      <el-table-column label="Where do you learn about EAE?" prop="form_data.where"></el-table-column>
      <el-table-column label="Contact Address" prop="form_data.contact_email"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button @click="view(scope.row)" type="primary" size="small">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="showD">
      <div style="margin-bottom:10px">
        <div>Name of Organization</div>
        <div style="font-size:18px">{{ curent.form_data.agencyName }}</div>
      </div>
      <div style="margin-bottom:10px">
        <div>Website</div>
        <div style="font-size:18px">{{ curent.form_data.web }}</div>
      </div>
      <div style="margin-bottom:10px">
        <div>Organization Type</div>
        <div style="font-size:18px">{{ curent.form_data.agencyType }}</div>
      </div>
      <div style="margin-bottom:10px">
        <div>Where do you learn about EAE?</div>
        <div style="font-size:18px">{{ curent.form_data.where }}</div>
      </div>
      <div style="margin-bottom:10px">
        <div>Contact Address</div>
        <div style="font-size:18px">{{ curent.form_data.contact_email }}</div>
      </div>
      <div style="margin-bottom:10px">
        <div>Registrant’s Name</div>
        <div style="font-size:18px">{{ curent.form_data.regName }}</div>
      </div>
      <div style="margin-bottom:10px">
        <div>Registrant’s Mobile</div>
        <div style="font-size:18px">{{ curent.form_data.regMobile }}</div>
      </div>
      <div style="margin-bottom:10px">
        <div>Registrant’s IM (Please also specify the IM platform)</div>
        <div style="font-size:18px">{{ curent.form_data.regWechat }}</div>
      </div>
      <div style="margin-bottom:10px">
        <div>Registrant’s Email Address</div>
        <div style="font-size:18px">{{ curent.form_data.regEmail }}</div>
      </div>
      <div style="margin-bottom:10px">
        <div>Registrant’s Title</div>
        <div style="font-size:18px">{{ curent.form_data.regTitle }}</div>
      </div>
      <div style="margin-bottom:10px">
        <div>Are you also the program adviser?</div>
        <div style="font-size:18px">{{ curent.form_data.adviserStatus }}</div>
      </div>
      <div style="margin-bottom:10px">
        <div>adviserName</div>
        <div style="font-size:18px">{{ curent.form_data.adviserName }}</div>
      </div>
      <div style="margin-bottom:10px">
        <div>adviserTitle</div>
        <div style="font-size:18px">{{ curent.form_data.adviserTitle }}</div>
      </div>
      <div style="margin-bottom:10px">
        <div>adviserEmail</div>
        <div style="font-size:18px">{{ curent.form_data.adviserEmail }}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getAccountForms } from '../../api/eae'
export default {
  data(){
    return{
      tableData:[],
      curent:{
        form_data:{}
      },
      showD:false
    }
  },
  mounted(){
    this.fetchData()
  },
  methods:{
    fetchData(){
      getAccountForms().then(res=>{
        if(res.data.code===0){
          this.tableData = res.data.data
        }
      })
    },
    view(item){
      this.curent = item
      this.showD = true
    }
  }
}
</script>

<style>

</style>